import {neutralColor} from '@/config/theme/colors';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from './navbar';
import {
  Sidebar,
  SidebarProvider,
  drawerClosedWidth,
  drawerWidth,
  useSidebar,
} from './sidebar';

export default function MainLayout({children, noGutters}) {
  return (
    <SidebarProvider>
      <Box sx={{display: 'flex'}}>
        <Navbar />
        <Box
          component="nav"
          sx={{
            width: {sm: drawerWidth},
            flexShrink: {sm: 0},
            position: 'fixed',
          }}
          aria-label="sidebar mobile"
        >
          <Sidebar />
        </Box>
        <MainContent noGutters={noGutters}>{children}</MainContent>
      </Box>
    </SidebarProvider>
  );
}

function MainContent({children, noGutters}) {
  const isOpen = useSidebar().isOpen;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: isOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
        mt: isMobile ? '40px' : '15px',
        backgroundColor: neutralColor[100],
        marginLeft: isMobile
          ? 0
          : isOpen
            ? `${drawerWidth}px`
            : `${drawerClosedWidth}px`,
        transition: 'margin-left 0.3s',
      }}
    >
      <Container maxWidth={false} disableGutters={noGutters}>
        {children}
      </Container>
    </Box>
  );
}
