// CORE
// Este é um arquivo core do boilerplate e modificações neste arquivo serão bloqueadas via pré-hook de commit.
// Fazemos este bloqueio para manter a consistência do boilerplate e diminuir as chances de conflitos nos updates.
// Caso possível, procure uma alternativa ao uso deste arquivo.
// Se a alteração for realmente necessária ou for um update do boilerplate,
// realize o commit usando o comando 'git commit --no-verify'.

import {colorTokens} from '@/config/theme/colors';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import {useRouter} from 'next/router';
import * as React from 'react';
import {createContext, useContext, useState} from 'react';
import {DrawerContents} from './sidebarContents';

export const drawerWidth = 280;
export const drawerClosedWidth = 88;

const SidebarContext = createContext();

export const SidebarProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('sidebar_state') != 'closed'
  );

  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    //localStorage é meio bugado quando não armazena string
    localStorage.setItem('sidebar_state', newState ? 'open' : 'closed');
  };

  return (
    <SidebarContext.Provider value={{isOpen, toggleSidebar}}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar deve estar dentro de um SidebarProvider');
  }
  return context;
};

export function Sidebar() {
  const {isOpen, toggleSidebar} = useSidebar();

  const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    ...theme.mixins.toolbar,
    position: isOpen ? 'relative' : 'relative',
    top: 0,
    right: 0,
  }));

  return (
    <>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isOpen ? drawerWidth : drawerClosedWidth,
            transition: 'width 0.3s',
            overflowX: 'hidden',
            zIndex: 3,
          },
        }}
      >
        <DrawerContents
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          DrawerHeader={DrawerHeader}
        />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: {xs: 'none', sm: 'block'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isOpen ? drawerWidth : drawerClosedWidth,
            transition: 'width 0.3s',
            overflowX: 'hidden',
            position: 'initial',
            height: '100vh',
            zIndex: 3,
          },
        }}
        open
      >
        <DrawerContents
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          DrawerHeader={DrawerHeader}
        />
      </Drawer>
    </>
  );
}

export function SidebarButton({
  name,
  Icon,
  path,
  isIndented,
  selectedCallback,
}) {
  const router = useRouter();
  const buttonSelected = router.pathname.endsWith(path);
  const {isOpen} = useSidebar();

  React.useEffect(() => {
    if (buttonSelected && !!selectedCallback) {
      selectedCallback();
    }
  }, [router.pathname, buttonSelected, selectedCallback]);

  let bg = buttonSelected ? colorTokens.sidebar.menuActiveItemBackground : null;
  const button = (
    <Button
      fullWidth
      size="large"
      variant={'text'}
      startIcon={
        <Icon sx={{marginLeft: '18px', marginRight: isOpen ? '18px' : '0'}} />
      }
      sx={{
        color: colorTokens.sidebar.menuIconActive,
        justifyContent: 'left',
        pl: isIndented ? '56px' : '4px',
        py: 0,
        height: '56px',
        minWidth: '56px',
        backgroundColor: bg,
        textWrap: 'nowrap',
      }}
    >
      <span
        style={{
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 0.8s',
        }}
      >
        {isOpen ? name : ''}
      </span>
    </Button>
  );
  if (path) return <Link href={path}>{button}</Link>;

  return button;
}

export function SectionTitle({title}) {
  return (
    <Typography
      variant="body-small"
      sx={{
        color: colorTokens.sidebar.menuIconActive,
        justifyContent: 'left',
        height: '40px',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        opacity: 0.6,
      }}
    >
      {title}
    </Typography>
  );
}

export function SidebarGroup({name, Icon, children}) {
  const {isOpen} = useSidebar();
  const [isExpanded, toggleExpanded] = React.useState(false);
  const setExpanded = () => {
    toggleExpanded(true);
  };
  const handleChange = (event, expanded) => {
    toggleExpanded(expanded);
  };
  return (
    <Accordion
      disableGutters={true}
      expanded={isExpanded}
      onChange={handleChange}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        '&:before': {display: 'none'},
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0,
          '& .MuiAccordionSummary-content': {display: 'block', margin: 0},
          justifyContent: 'left',
        }}
      >
        <SidebarButton name={name} Icon={Icon} />
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0}}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            isIndented: isOpen,
            selectedCallback: setExpanded,
          })
        )}
      </AccordionDetails>
    </Accordion>
  );
}
