import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LanguageIcon from '@mui/icons-material/Language';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Image from 'next/image';
import nextLogo from '../../public/next.svg';
import {SectionTitle, SidebarButton, SidebarGroup} from './sidebar';

export const DrawerContents = ({isOpen, toggleSidebar, DrawerHeader}) => (
  <Box px="16px">
    <DrawerHeader sx={{padding: '8px !important'}}>
      <IconButton disableRipple onClick={toggleSidebar} color="inherit">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          gap={2}
        >
          {isOpen ? (
            <Box>
              <MenuOpenIcon sx={{marginRight: '14px'}} />
              <Image
                src={nextLogo}
                alt="Talentum Boilerplate Logo"
                height={25}
              />
            </Box>
          ) : (
            <Box>
              <MenuOpenIcon sx={{transform: 'rotate(180deg)'}} />
            </Box>
          )}
        </Box>
      </IconButton>
    </DrawerHeader>
    <SidebarButton name="Início" Icon={LanguageIcon} path="/" />
    <SidebarButton
      name="Tipografia"
      Icon={FontDownloadIcon}
      path="/typography"
    />
    <SidebarButton name="Kanban" Icon={ViewKanbanIcon} path="/kanban" />
    <SidebarButton name="Formulários" Icon={DynamicFormIcon} path="/forms" />
    <SidebarButton
      name="Notificações"
      Icon={NotificationsIcon}
      path="/notifications"
    />
    <SectionTitle title="Configurações" />
    <SidebarGroup name="Configuraçes" Icon={SettingsIcon}>
      <SidebarButton name="Usuários" Icon={AccountCircleIcon} path="/users" />
      <SidebarButton name="Convites" Icon={AccountCircleIcon} path="/invites" />
    </SidebarGroup>
  </Box>
);
